import bytes from 'bytes';
import { useEffect, useState } from 'react';

import { client } from 'app/http';
import { audioTypes } from '@helpers/audioTypes';

import { TrashBin } from '@components/icons/TrashBin';
import { Download } from '@components/icons/Download';
import { Jobs as TryAgainIcon } from '@components/icons/Jobs';
import { UploadObjectType } from '@constants/enums/uploadObjectType';

import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';

import { Loader } from '../Loader';
import { getDownloadLink } from './helpers/getDownloadLink';
import { secondsToHHMMSS } from '@helpers/secondsToHHMMSS';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { ErrorMessage } from './styles/ErrorMessage';

import { Attention as AttentionIcon } from './icons/Attention';
import { Microphone as MicrophoneIcon } from './icons/Microphone';
import { Document as DocumentIcon } from './icons/Document';
import { RepositoriesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { AudioPlayer } from '@components/AudioPlayer';
import { sanitizeFileName } from '@helpers/sanitizeFileName';

export function PreviewFile({
  fileWithMeta,
  hardCodeName = '',
  draggable = false,
  objectType,
  allowPlayback,
  hideRemoveButton = false,
  hideDownloadButton = false,
  onRemoveFile,
}: TProps) {
  const { call } = useAPI();
  const { form } = usePageContext();
  const { name, percent, status, size, Id, duration } = fileWithMeta.meta;
  const fileType = (name || '').split('.').pop()?.toLowerCase();
  const isAudioFile = audioTypes.includes(`.${fileType}`);
  const isTrmFile = fileType === 'trm';
  const [playbackUrl, setPlaybackUrl] = useState(null);

  async function handleRemove() {
    if (!onRemoveFile) {
      return;
    }
    fileWithMeta.meta.status = 'removed';
    onRemoveFile(fileWithMeta);
    if (!Id) {
      console.error('No ID provided!', fileWithMeta, fileWithMeta.meta);
      return;
    } else {
      if (objectType === UploadObjectType.JOB) {
        client.delete('media', {
          headers: {
            'X-JobId': form.item?.id,
            'X-MediaId': hardCodeName ? hardCodeName : name,
            'X-Id': Id,
          },
        });
      }
      if (objectType === UploadObjectType.REPOSITORY) {
        await call(RepositoriesService.removeAttachments({ id: Id }));
      }
    }
  }

  useEffect(() => {
    if (
      !Id ||
      !allowPlayback ||
      !isAudioFile ||
      status !== 'done' ||
      objectType !== UploadObjectType.REPOSITORY ||
      isTrmFile
    ) {
      return;
    }
    const getPlaybackUrl = async () => {
      const data = await call(RepositoriesService.downloadAttachment({ id: Id }));
      if (!data) {
        console.error('No data in response! Cannot set playback up.', data);
        return;
      }
      setPlaybackUrl(data.url);
    };

    getPlaybackUrl();
  }, [allowPlayback, isAudioFile, status, objectType, Id]);

  const fileName = (input: string) => {
    const name = sanitizeFileName(input);
    if (name?.length > 64) {
      return name.slice(0, 55) + '...' + name.slice(-6);
    }
    return name;
  };

  const renderSecondLine = () => {
    if (status === 'done' && duration && duration !== Infinity) {
      return (
        <span>
          Duration: {secondsToHHMMSS(duration ?? 0)} ({bytes(size)})
        </span>
      );
    }
    if (status === 'done' && duration === Infinity) {
      return <span>Duration: Unrecognized ({bytes(size)})</span>;
    }
    if (status === 'done' && !duration) {
      return <span>File uploaded ({bytes(size)})</span>;
    }
    if (status === 'calculating_duration') {
      return <span>Analyzing...</span>;
    }
    return <span>Uploading: {Math.round(percent) + `%`}</span>;
  };

  const renderIcon = () => {
    if (status === 'done' && duration === 0) {
      return <AttentionIcon />;
    }
    if (status === 'done' && isAudioFile) {
      return <MicrophoneIcon />;
    }
    if (status !== 'done' && status !== 'error_upload') {
      return <Loader size={20} />;
    }
    if (status === 'error_upload') {
      return <AttentionIcon />;
    }
    return <DocumentIcon />;
  };

  if (status === 'removed') {
    return null;
  }

  if (status === 'done' && duration === 0) {
    return (
      <div>
        <Wrapper className="preview-file" draggable={false} error={true}>
          <div className="PreviewFile-row">
            <div className="PreviewFile-left-side-wrapper">
              <div className="PreviewFile-fileIcon">{renderIcon()}</div>
              <span className="PreviewFile-file-name-wrapper">
                <span>{fileName(name)}</span>
                {renderSecondLine()}
              </span>
            </div>

            <div className="flex">
              {objectType === UploadObjectType.REPOSITORY && status === 'done' && !hideDownloadButton ? (
                <button
                  className="PreviewFile-download-button"
                  data-testid="download-icon"
                  type="button"
                  onClick={() => getDownloadLink(Id, objectType, fileWithMeta)}
                >
                  <Download />
                </button>
              ) : null}

              {onRemoveFile && (status === 'done' || status === 'error_upload') && !hideRemoveButton ? (
                <button className="PreviewFile-button PreviewFile-removeButton" type="button" onClick={handleRemove}>
                  <TrashBin />
                </button>
              ) : null}
            </div>
          </div>
        </Wrapper>

        <ErrorMessage>
          There was a problem analyzing your audio file. Please ensure the file will play in the audio player on your
          device. If playback is successful and the file is audible, please try uploading again. If the issue persists,
          please contact our{' '}
          <a
            href="https://support.voicescript.ai/portal/en/home"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            support team
          </a>{' '}
          for further assistance.
        </ErrorMessage>
      </div>
    );
  }

  return (
    <Wrapper className="preview-file" draggable={draggable && !allowPlayback} error={false}>
      <div className="PreviewFile-row">
        <div className="PreviewFile-left-side-wrapper">
          <div className="PreviewFile-fileIcon">{renderIcon()}</div>
          <span className="PreviewFile-file-name-wrapper">
            <span>{fileName(name)}</span>
            {renderSecondLine()}
          </span>
        </div>

        {status === 'error_upload' ? <div className="errorText">Error during uploading </div> : null}

        {status === 'error_upload' ? (
          <button className="PreviewFile-button PreviewFile-retryButton" type="button" onClick={fileWithMeta.restart}>
            <TryAgainIcon />
          </button>
        ) : null}

        <div className="flex">
          {objectType === UploadObjectType.REPOSITORY && status === 'done' && !hideDownloadButton ? (
            <button
              className="PreviewFile-download-button"
              data-testid="download-icon"
              type="button"
              onClick={() => getDownloadLink(Id, objectType, fileWithMeta)}
            >
              <Download />
            </button>
          ) : null}

          {onRemoveFile && (status === 'done' || status === 'error_upload') && !hideRemoveButton ? (
            <button className="PreviewFile-button PreviewFile-removeButton" type="button" onClick={handleRemove}>
              <TrashBin />
            </button>
          ) : null}
        </div>
      </div>
      <div className="PreviewFile-row">
        {playbackUrl && !isTrmFile ? (
          <div className="PreviewFile-audio-wrapper">
            <AudioPlayer src={playbackUrl} />
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
}

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { routes } from '@routes';
import { bulkJobSubmission } from '@queries/repositories/bulkJobSubmission';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { PreviewFile } from '@components/FileUpload/components/PreviewFile';
import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { formatDuration } from '@components/FileUpload/components/dropzone';
import { useAuthContext } from '@providers/AuthProvider';
import { Link as RouterLink } from 'react-router-dom';
import { getRepoAudioFiles } from '@queries/repositories/getRepoAudioFiles';
import { mapAttachmentsToIFileWithMeta, RepositoryAttachment } from '@pages/User/RepositoryPage/utils';
import { Block } from '@components/Block';
import { Switch } from '@components/form-elements/Switch';
import { TJob } from 'app/types/entities/TJob';
import { TRepository } from 'app/types/entities/TRepository';
import { TAudioFile } from 'app/types/entities/TAudioFile';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { PayService } from 'app/API';

type TProps = {
  repository: TRepository;
  audioFiles: TAudioFile[];
  organizationId: string;
  repositoryId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const StyledLink = styled(RouterLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #858dbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const StyledBlock = styled(Block)`
  padding-bottom: 0;
  text-align: left;

  .leftSide {
    padding-right: 25px;
    max-width: 200px;
  }

  .rightSide {
    display: flex;
    align-items: center;
  }

  .rightSide > * {
    margin-bottom: 0 !important;
  }
`;

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  // width: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  .repositories-errorMessage {
    color: #ff2f2f;
    margin-top: -20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    margin: 0;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: 130px;
    }
  }
`;

enum BulkJobStep {
  GetAudioDuration = 'retrieving_audio_duration',
  RetrievingPrice = 'retrieving_price',
  RetrievedPrice = 'retrieved_price',
  CreatingDraftJobs = 'creating_draft_jobs',
  DraftJobsCreated = 'draft_jobs_created',
  JobsSubmittedSuccessfully = 'jobs_submitted_successfully',
  JobsSubmittedWithErrors = 'jobs_submitted_with_errors',
}

type BulkJobCreationModalButtonLabel = 'Create Jobs' | 'Submit Jobs' | 'Close';

export const BulkJobCreationModal = ({ repositoryId, organizationId, onCancel }: TProps) => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [step, setStep] = useState(BulkJobStep.GetAudioDuration);
  const [totalAudioDuration, setTotalAudioDuration] = useState<number>();
  const [buttonLabel, setButtonLabel] = useState<BulkJobCreationModalButtonLabel>(
    'Create Jobs' as BulkJobCreationModalButtonLabel,
  );
  const [createdJobs, setCreatedJobs] = useState<TJob[]>([]);
  const [audioFiles, setAudioFiles] = useState<TFileWithMeta[]>([]);
  const [dualChannel, setDualChannel] = useState(false);

  const { me, workspace, organization } = useAuthContext();

  const billingAccount = me?.organizations?.find((o) => o.id === organizationId)?.billingAccount;

  const totalPrice = totalAudioDuration
    ? (totalAudioDuration / 3600) * (Number(billingAccount?.postProcessingASRRate) || 0)
    : 0;

  useEffect(() => {
    async function setup() {
      const af = await getRepoAudioFiles(repositoryId);
      // console.log('af', af);
      setAudioFiles(mapAttachmentsToIFileWithMeta(af));

      const totalDuration = af
        .map((a: RepositoryAttachment) => a.audioDuration || 0)
        .reduce((acc: number, curr: number) => acc + curr, 0);
      setTotalAudioDuration(totalDuration);
      setStep(BulkJobStep.RetrievedPrice);
      setIsSubmitting(false);
    }

    if (step === BulkJobStep.GetAudioDuration) {
      setup();
    }
  }, [repositoryId, step]);

  const draftJobCreationHandler = async () => {
    if (!workspace?.id) {
      return;
    }

    setIsSubmitting(true);
    const data = await bulkJobSubmission(repositoryId, workspace?.id, dualChannel);
    console.log('draftJobCreationHandler', data);
    if (!data.error) {
      // onSuccess();
      setCreatedJobs(data);
      setStep(BulkJobStep.DraftJobsCreated);
      setButtonLabel('Submit Jobs');
    } else {
      setError(data.error);
    }
    setIsSubmitting(false);
  };

  const jobSubmissionHandler = async () => {
    setIsSubmitting(true);
    await Promise.all(createdJobs.map(async (job) => await PayService.pay({ id: job.id })));

    setStep(BulkJobStep.JobsSubmittedSuccessfully);
    setIsSubmitting(false);
    setButtonLabel('Close');
  };

  const buttonOnClick = () => {
    if (step === BulkJobStep.RetrievedPrice) {
      return draftJobCreationHandler;
    }

    if (step === BulkJobStep.DraftJobsCreated) {
      return jobSubmissionHandler;
    } else {
      return onCancel;
    }
  };

  const onRemoveFile = (file: TFileWithMeta) => {
    const removeFilter = (f: TFileWithMeta) => f.meta.Id !== file.meta.Id;
    setAudioFiles([...audioFiles.filter(removeFilter)]);
  };

  return (
    <ModalWrapper>
      <h2>Bulk Job Creation</h2>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <div>
        {step === BulkJobStep.GetAudioDuration && <div>Retrieving audio duration...</div>}

        {step === BulkJobStep.RetrievedPrice && audioFiles.length === 0 && <div>No audio files found</div>}

        {step === BulkJobStep.RetrievedPrice &&
          audioFiles.map((a) => (
            <PreviewFile
              key={a.meta.Id}
              fileWithMeta={a}
              objectType={UploadObjectType.REPOSITORY}
              hideDownloadButton={true}
              hideRemoveButton={true}
              onRemoveFile={onRemoveFile}
            />
          ))}

        {step === BulkJobStep.DraftJobsCreated ? (
          <div>
            <p className="text-[#858DBD] font-[600] text-left text-sm pb-2">Draft jobs created</p>
            {createdJobs.map((job) => (
              <div key={job.id}>
                <StyledLink to={routes.jobEdit.make(organization?.slug ?? '', workspace?.slug ?? '', job.id)}>
                  {job.name}
                </StyledLink>{' '}
              </div>
            ))}
          </div>
        ) : null}

        {step === BulkJobStep.JobsSubmittedSuccessfully ? <div>Jobs submitted successfully</div> : null}
      </div>
      {error ? <div className="repositories-errorMessage">{error}</div> : null}

      {step === BulkJobStep.RetrievedPrice && audioFiles.length ? (
        <div>
          <StyledBlock title="Dual-Channel Call Processing">
            <Switch checked={dualChannel} onChange={() => setDualChannel(!dualChannel)} />
          </StyledBlock>
          <p className="text-xs text-left text-[#878787]">
            Enable this option if your phone call recording uses two channels <br />
            (e.g., one for each speaker).
          </p>
        </div>
      ) : null}

      {audioFiles.length > 0 ? (
        <table className="text-left text-xs border-collapse">
          <tbody>
            <tr className="border-b border-solid border-[#D5DEF2]">
              <td className="text-[#858DBD] font-[600] py-2">Total duration</td>
              <td className="text-sm text-right py-2">
                {totalAudioDuration ? formatDuration(totalAudioDuration) : <i>Loading...</i>}
              </td>
            </tr>
            <tr>
              <td className="text-[#858DBD] font-[600] py-2">Total price for bulk job</td>
              <td className="text-sm font-[600] text-right py-2">${totalPrice.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-4" onClick={buttonOnClick()} disabled={isSubmitting}>
          {buttonLabel}
        </Button>
      </div>
    </ModalWrapper>
  );
};

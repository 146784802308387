import React from 'react';
import { Toaster } from 'react-hot-toast';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Modal } from '@components/Modal/Modal';
import { PaymentMethodsHistory } from '@components/Modal/PaymentMethodsHistory';
import { ContactSales } from '@components/Modal/ContactSales';
import { PaymentForm } from '@components/Modal/PaymentForm';
import { CreateUserModal } from '@components/Modal/CreateUserModal';
import { PriceModal } from '@components/Modal/PriceModal';
import { ResubmitJobModal } from '@components/Modal/ResubmitJobModal';
import { DeleteJobModal } from '@components/Modal/confirmations/DeleteJobModal';
import { InviteUserModal } from '@components/Modal/InviteUserModal';
import { DeleteInvitationModal } from '@components/Modal/confirmations/DeleteInvitationModal';
import { DeleteWorkspaceModal } from '@components/Modal/Workspaces/DeleteWorkspaceModal';
import { AssignJobModal } from '@components/Modal/Jobs/AssignJobModal';
import { EditDeadlineModal } from '@components/Modal/EditDeadlineModal';
import { JobHistory } from '@components/Modal/JobHistory';
import { MoveJobToWSModal } from '@components/Modal/MoveJobToWSModal';
import { NewWorkspaceModal } from '@components/Modal/NewWorkspaceModal';
import { ImageUploadModal } from '@components/Modal/ImageUpload';
import { NewOrganizationModal } from '@components/Modal/NewOrganizationModal';
import { AddTeamMembersModal } from '@components/Modal/Team/AddTeamMembersModal';
import { AddTeamToWorkspaceModal } from '@components/Modal/Team/AddTeamToWorkspaceModal';
import { DeleteRepositoryModal } from '@components/Modal/Repositories/DeleteRepositoryModal';
import { DeleteTeamModal } from '@components/Modal/Team/DeleteTeamModal';
import { AdminInviteUserModal } from '@components/Modal/Admin/InviteUserModal';
import { NewTeamModal } from '@components/Modal/NewTeamModal';
import { GenericErrorModal } from '@components/Modal/errors/GenericErrorModal';
import { DeleteMemberModal } from '@components/Modal/confirmations/DeleteMemberModal';
import { AssignRepositoryModal } from '@components/Modal/Repositories/AssignRepositoryModal';
import { EditJobNotesModal } from '@components/Modal/JobDetails/EditJobNotesModal';
import { EditJobTagsModal } from '@components/Modal/JobDetails/EditJobTagsModal';
import { SelectCaseFiles } from '@components/Modal/Jobs/SelectCaseFiles';
import { GetAssigneeModal } from '@components/Modal/Jobs/GetAssigneeModal';
import { ChooseASOneSubscriptionModal } from '@components/Modal/ASOne/ChooseASOneSubscriptionModal';
import { ConfirmSubscription } from '@components/Modal/ASOne/ConfirmSubscription';
import { AddUserLicenseModal } from '@components/Modal/ASOne/AddUserLicenseModal';
import { AdminResubmitJobModal } from '@components/Modal/Admin/AdminResubmitJobModal';
import { ConfirmSubscriptionCancellation } from '@components/Modal/ASOne/ConfirmSubscriptionCancellation';
import { NewASOnePlanModal } from '@components/Modal/Admin/NewASOnePlanModal';
import { EditASOnePlanModal } from '@components/Modal/Admin/EditASOnePlanModal';
import { TopUpCreditBalanceModal } from '@components/Modal/Billing/TopUpCreditBalanceModal';
import { AdminAddBalanceToBillingAccountModal } from '@components/Modal/Admin/AddBalanceToBillingAccount';
import { EditASOneSubscriptionModal } from '@components/Modal/ASOne/EditASOneSubscriptionModal';
import { AdminBillingAccountRateModal } from '@components/Modal/Admin/BillingAccountRate';
import { CreateNewSMSCountry } from '@components/Modal/Admin/CreateNewSMSCountry';
import { CreateNewWhitelistNumber } from '@components/Modal/Admin/CreateNewWhitelistNumber';
import { AdminRefundJobModal } from '@components/Modal/Admin/AdminRefundJobModal';
import { BulkJobCreationModal } from '@components/Modal/Repositories/BulkSubmitJobsModal';
import { GetAssigneeWithoutCommentModal } from '@components/Modal/Jobs/GetAssigneeWithoutCommentModal';
import { AddAdminTransactionModal } from '@components/Modal/Admin/AddAdminTransactionModal';
import { AdminEditUserEmailModal } from '@components/Modal/Admin/EditUserEmailModal';
import { AdminEditUserPhoneModal } from '@components/Modal/Admin/EditUserPhoneModal';
import { AdminEditSettingModal } from '@components/Modal/Admin/AdminEditSettingModal';
import { AdminBillingAccountASOneRateModal } from '@components/Modal/Admin/AdminBillingAccountASOneRateModal';
import { UnlockJobModal } from '@components/Modal/UnlockJobModal';
import { EditConcurrentJobsLimitModal } from '@components/Modal/EditConcurrentJobsLimitModal';
import { InviteUsersByCsvModal } from '@components/Modal/InviteUsersByCsvModal';
import { ResendInviteModal } from '@components/Modal/ResendInviteModal';
import { StatusModal } from '@components/Modal/StatusModal';
import { TransactionStatusModal } from '@components/Modal/Billing/TransactionStatusModal';
import { OpenJobVersionModal } from '@components/Modal/OpenJobVersion';
import { WhatsNewModal } from '@components/Modal/WhatsNew';
import { useLocation } from 'react-router-dom';
import { SearchModal } from '@components/Modal/Search';
import { AdminConfirmSubscriptionCancellation } from '@components/Modal/ASOne/AdminConfirmSubscriptionCancellation';
import { MoveJobsToWSModal } from '@components/Modal/MoveJobsToWSModal';
import { DeleteJobsModal } from '@components/Modal/confirmations/DeleteJobsModal';
import { AdminMoveJobToOrgWsModal } from '@components/Modal/Admin/MoveJobToOrgWsModal';
import { EditJobCaseModal } from '@components/Modal/JobDetails/EditJobCaseModal';
import { DeleteJobFromCaseModal } from '@components/Modal/confirmations/DeleteJobFromCaseModal';
import { DeleteAPIKeyModal } from '@components/Modal/confirmations/DeleteAPIKeyModal';
import { CreateAPIKeyModal } from '@components/Modal/CreateAPIKeyModal';

type TProps = {
  children: React.ReactNode;
};

type TState = {
  closeModal: () => void;
  openModal: (modalName: keyof typeof ModalNamesEnum, modalProps?: object) => void;
};

const ModalContext = React.createContext<TState>({
  closeModal: () => {},
  openModal: () => {},
});

// When we have a new version just update this key. It will pop again
const whatsNewLSKey = 'disableWhatsNew-2024-04';

export const ModalProvider = ({ children }: TProps) => {
  const [openedModal, setOpenedModal] = React.useState('');
  const [modalProps, setModalProps] = React.useState<any>({});
  const location = useLocation();
  const [whatsNewDisabled, setWhatsNewDisabled] = React.useState<any>(window.localStorage.getItem(whatsNewLSKey));

  const cancelCloseModal = () => {
    if (modalProps?.onCancel) {
      modalProps.onCancel();
    }
    setOpenedModal('');
    setModalProps(undefined);
  };

  const closeModal = () => {
    setOpenedModal('');
    setModalProps(undefined);
  };

  const openModal = (modalName: keyof typeof ModalNamesEnum, modalProps?: object) => {
    setModalProps(modalProps);
    setOpenedModal(modalName);
  };

  if (!whatsNewDisabled && location.pathname.includes('/dashboard')) {
    const disableWhatsNew = () => {
      window.localStorage.setItem(whatsNewLSKey, 'true');
      setWhatsNewDisabled(true);
    };
    return (
      <ModalContext.Provider value={{ openModal, closeModal }}>
        {children}
        <Modal isOpen={true} onClose={disableWhatsNew}>
          <WhatsNewModal onCancel={disableWhatsNew} />
        </Modal>

        <Toaster containerStyle={{ zIndex: '999999' }} position="bottom-right" reverseOrder={false} />
      </ModalContext.Provider>
    );
  }

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal isOpen={!!openedModal} onClose={cancelCloseModal}>
        {openedModal === ModalNamesEnum.WhatsNew ? <WhatsNewModal {...modalProps} /> : null}
        {openedModal === ModalNamesEnum.AdminEditSetting ? (
          <AdminEditSettingModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminAddBalanceToBillingAccount ? (
          <AdminAddBalanceToBillingAccountModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminBillingAccountRate ? (
          <AdminBillingAccountRateModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminBillingAccountASOneRate ? (
          <AdminBillingAccountASOneRateModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.PaymentMethodsHistory ? (
          <PaymentMethodsHistory onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ContactSales ? <ContactSales onCancel={closeModal} {...modalProps} /> : null}

        {openedModal === ModalNamesEnum.AddPaymentMethod ? (
          <PaymentForm onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.TopUpCreditBalance ? (
          <TopUpCreditBalanceModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.Status ? <StatusModal onSuccess={closeModal} {...modalProps} /> : null}
        {openedModal === ModalNamesEnum.TransactionStatus ? (
          <TransactionStatusModal onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.CreateUserModal ? (
          <CreateUserModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.PriceModal ? (
          <PriceModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.SearchModal ? (
          <SearchModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.UnlockJob ? (
          <UnlockJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.DeleteJobModal ? (
          <DeleteJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.DeleteJobFromCaseModal ? (
          <DeleteJobFromCaseModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.DeleteJobsModal ? (
          <DeleteJobsModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ResubmitJobModal ? (
          <ResubmitJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminResubmitJobModal ? (
          <AdminResubmitJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminMoveJobModal ? (
          <AdminMoveJobToOrgWsModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminRefundJobModal ? (
          <AdminRefundJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.InviteUserModal ? (
          <InviteUserModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.InviteUsersByCsvModal ? (
          <InviteUsersByCsvModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.DeleteInvitationModal ? (
          <DeleteInvitationModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminInviteUserModal ? (
          <AdminInviteUserModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.NewWorkspaceModal ? (
          <NewWorkspaceModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.DeleteAPIKey ? (
          <DeleteAPIKeyModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.CreateAPIKey ? (
          <CreateAPIKeyModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.DeleteWorkspaceModal ? (
          <DeleteWorkspaceModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.AssignJobModal ? (
          <AssignJobModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.GetAssigneeModal ? (
          <GetAssigneeModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.GetAssigneeWithoutCommentModal ? (
          <GetAssigneeWithoutCommentModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.AddUserLicenseModal ? (
          <AddUserLicenseModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.EditJobNotesModal ? (
          <EditJobNotesModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.EditJobCaseModal ? (
          <EditJobCaseModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.EditJobTagsModal ? (
          <EditJobTagsModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.EditDeadline ? (
          <EditDeadlineModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.MoveJobToWSModal ? (
          <MoveJobToWSModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.MoveJobsToWSModal ? (
          <MoveJobsToWSModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.JobHistory ? <JobHistory onCancel={closeModal} {...modalProps} /> : null}

        {openedModal === ModalNamesEnum.ImageUploadModal ? (
          <ImageUploadModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.NewOrganizationModal ? (
          <NewOrganizationModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.AddTeamMembersModal ? (
          <AddTeamMembersModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.AddTeamToWorkspaceModal ? (
          <AddTeamToWorkspaceModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.DeleteRepositoryModal ? (
          <DeleteRepositoryModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.DeleteMemberModal ? (
          <DeleteMemberModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.DeleteTeamModal ? (
          <DeleteTeamModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.NewTeamModal ? <NewTeamModal onCancel={closeModal} {...modalProps} /> : null}

        {openedModal === ModalNamesEnum.GenericErrorModal ? (
          <GenericErrorModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.AssignRepositoryModal ? (
          <AssignRepositoryModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.SelectCaseFiles ? (
          <SelectCaseFiles onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ChooseASOneSubscriptionModal ? (
          <ChooseASOneSubscriptionModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.EditASOneSubscriptionModal ? (
          <EditASOneSubscriptionModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ConfirmSubscription ? (
          <ConfirmSubscription onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ConfirmSubscriptionCancellation ? (
          <ConfirmSubscriptionCancellation onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AdminConfirmSubscriptionCancellation ? (
          <AdminConfirmSubscriptionCancellation onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AddNewASOnePlan ? (
          <NewASOnePlanModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.EditASOnePlan ? (
          <EditASOnePlanModal onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.CreateNewSMSCountry ? (
          <CreateNewSMSCountry onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.CreateNewWhitelistNumber ? (
          <CreateNewWhitelistNumber onCancel={closeModal} onSuccess={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.BulkJobCreationModal ? (
          <BulkJobCreationModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.AddAdminTransactionModal ? (
          <AddAdminTransactionModal onCancel={closeModal} {...modalProps} />
        ) : null}

        {openedModal === ModalNamesEnum.EditUserPhone ? (
          <AdminEditUserPhoneModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.EditUserEmail ? (
          <AdminEditUserEmailModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.EditConcurrentJobsLimit ? (
          <EditConcurrentJobsLimitModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.ResendInvitationModal ? (
          <ResendInviteModal onCancel={closeModal} {...modalProps} />
        ) : null}
        {openedModal === ModalNamesEnum.OpenJobVersion ? (
          <OpenJobVersionModal onCancel={closeModal} {...modalProps} />
        ) : null}
      </Modal>

      <Toaster containerStyle={{ zIndex: '999999' }} position="bottom-right" reverseOrder={false} />
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);

import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '@routes';
import { TUser } from 'app/types/entities/TUser';
import { EPermission } from 'app/types/enums/EPermission';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { EACCEPTED_DOCUMENT_FORMATS } from 'app/types/enums/EACCEPTED_DOCUMENT_FORMATS';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { useAuthContext } from '@providers/AuthProvider';
import { Restricted } from '@providers/PermissionProvider/Restricted';

import { FileUpload } from '@components/FileUpload';
import { Input } from '@components/form-elements/Input';
import { SmallWhiteTextarea } from '@components/form-elements/Textarea/variations/SmallWhiteTextarea';
import { Block } from '@components/Block';

const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;

const InfoBlockContent = styled.div`
  color: #00122d;
`;

const AssigneeBlockContent = styled(InfoBlockContent)`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin: 0;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      margin: 0;
    }
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

export type TForm = {
  id: string;
  organizationId: string;
  name: string;
  slug: string;
  notes: string;
  assignee: TUser | null;
  audioFiles: string[];
  documentFiles: string[];
};

type TProps = {
  repositoryId: string;
  audioFiles: TFileWithMeta[];
  audioFilesFromServer: TFileWithMeta[];
  documentFiles: TFileWithMeta[];
  documentFilesFromServer: TFileWithMeta[];
  isFilesLoading: boolean;
  form: TForm;
};

export const MainBlock = ({ repositoryId, audioFiles, documentFiles, isFilesLoading, form }: TProps) => {
  const { organization } = useAuthContext();

  const notesDescription = <p>You can provide additional information</p>;
  const attachmentsDescription = <p>You can provide additional documents, exhibits and audio files.</p>;
  const audioAttachmentsDescription = <p>You can add video and audio files to submit as jobs.</p>;
  const assigneeDescription = <p>You can assign a case to a specific user</p>;

  return (
    <>
      <Block title="Provide a unique title for the case">
        <SmallWhiteInput
          onChange={() => {}}
          name="name"
          label="Title"
          value={form.name ?? ''}
          required={true}
          disabled={true}
        />
      </Block>
      <Block title="Case link">
        <SmallWhiteInput
          onChange={() => {}}
          name="name"
          label="Case ID"
          value={form.slug ?? ''}
          required={true}
          disabled={true}
        />
      </Block>
      <Block title="Notes" description={notesDescription}>
        <SmallWhiteTextarea
          onChange={() => {}}
          name="notes"
          label="Type your notes here"
          value={form.notes ?? ''}
          disabled={true}
        />
      </Block>
      <Block title="Attachments" description={attachmentsDescription}>
        <FileUpload
          objectId={repositoryId}
          objectType={UploadObjectType.REPOSITORY}
          files={documentFiles}
          setFiles={() => {}}
          isFilesLoading={isFilesLoading}
          autoUpload={false}
          accept={EACCEPTED_DOCUMENT_FORMATS.join(',')}
          onChangeStatus={() => {}}
        />
        <div className="uploadDescription">
          Upload your files here. You can upload multiple files at once. Supported file types: .doc, .docx, .txt, .pdf,
          .rtf
        </div>
      </Block>

      <Block title="Audio files" description={audioAttachmentsDescription}>
        <FileUpload
          objectId={repositoryId}
          objectType={UploadObjectType.REPOSITORY}
          files={audioFiles}
          setFiles={() => {}}
          isFilesLoading={isFilesLoading}
          autoUpload={false}
          accept={EACCEPTED_AUDIO_FORMATS.join(',')}
          allowPlayback={true}
          onChangeStatus={() => {}}
        />
        <div className="uploadDescription">
          Upload audio files here. You can upload multiple files at once. Supported file types include: Zoom
          multi-channel audio, For The Record (FTR), MP3, WAV and other audio formats.
        </div>
      </Block>

      <Restricted to={EPermission.editRepositories}>
        <>
          {repositoryId ? (
            <Block title="Assignee" description={assigneeDescription}>
              <AssigneeBlockContent>
                {form.assignee ? (
                  <div className="assignee">
                    <StyledLink to={routes.userDetails.make(organization?.slug ?? '', form.assignee?.id)}>
                      <p className="assignName">
                        {form.assignee?.name} {form.assignee?.lastname}
                      </p>
                      <p className="assignEmail">{form.assignee?.email || ''}</p>
                    </StyledLink>
                  </div>
                ) : null}
              </AssigneeBlockContent>
            </Block>
          ) : null}
        </>
      </Restricted>
    </>
  );
};

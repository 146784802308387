import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';

const StyledSwitchButtonWrapper = styled.div`
  display: flex;
  margin: 0 0 20px;

  .button {
    border-radius: 0;
    background: #ffffff;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    width: max-content;
    padding: 8px 15px;
  }

  .active {
    background: #d5def2;
  }

  .left {
    border-radius: 5px 0 0 5px;
  }

  .right {
    border-radius: 0 5px 5px 0;
  }
`;

type TProps = {
  tab: string;
  setTab: (v: string) => void;
};

export const Tabs = ({ tab, setTab }: TProps) => {
  return (
    <StyledSwitchButtonWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          setTab('words');
        }}
        className={`button left ${tab === 'words' ? 'active' : ''}`}
      >
        By Words
      </Button>
      <Button
        onClick={() => {
          setTab('lines');
        }}
        className={`button right ${tab === 'lines' ? 'active' : ''}`}
      >
        By Lines
      </Button>
    </StyledSwitchButtonWrapper>
  );
};
